<template>
  <div>
    <div class="contain">
      <div class="finish">
        <!-- 成功提示 -->
        <div class="success">
          <div class="icon"><i class="el-icon-success"></i></div>
          <div class="tips">恭喜您，简历创建成功</div>
          <div class="preview">简历预览</div>
          <div>完善简历可以获得更好的工作机会</div>
        </div>
        <!-- 目录 -->
        <div class="menu">
          <div
            class="list"
            @click="goto(itm.url)"
            v-for="(item, index) in menu"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- 岗位推荐 -->
        <div class="post">
            <div class="postTitle">你可能感兴趣的职位</div>
            <div class="postList">
                <div class="postItem" v-for="(tap,post) in postList" :key="post" @click="goto(tap.url)">
                    <div class="flex">
                        <div class="postName">{{tap.name}}</div>
                        <div class="salary">{{tap.salary}}</div>
                    </div>
                    <div class="flex1">
                        <div class="factor" v-for="(factorItem,factor) in tap.factorList" :key="factor">
                            {{factorItem.factorName}}
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="flex1">
                        <div class="logo"><img :src="tap.pic" alt=""></div>
                        <div class="info">
                            <div class="company">浙江广天电子科技有限公司</div>
                            <div><b>{{tap.count}}</b>个热招职位</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu: [
        {
          name: "完善简历",
          url: "",
        },
        {
          name: "完善个人标签",
          url: "",
        },
        {
          name: "求职状态",
          url: "",
        },
        {
          name: "自我介绍",
          url: "",
        },
        {
          name: "上传头像",
          url: "",
        },
      ],
      postList:[
          {
              name:"IOS高级工程师",
              salary:"6-8K",
              factorList:[
                  {
                      factorName:"金华"
                  },
                  {
                      factorName:"|"
                  },
                  {
                      factorName:"3年以上经验"
                  },
                  {
                      factorName:"|"
                  },
                  {
                      factorName:"大学学历"
                  },
                  {
                      factorName:"|"
                  },
                  {
                      factorName:"全职"
                  }
              ],
              pic:require('@/assets/img/gtlogo.png'),
              count:"5",
              url:''
          },
          {
              name:"IOS高级工程师",
              salary:"6-8K",
              factorList:[
                  {
                      factorName:"金华"
                  },
                  {
                      factorName:"|"
                  },
                  {
                      factorName:"3年以上经验"
                  },
                  {
                      factorName:"|"
                  },
                  {
                      factorName:"大学学历"
                  },
                  {
                      factorName:"|"
                  },
                  {
                      factorName:"全职"
                  }
              ],
              pic:require('@/assets/img/gtlogo.png'),
              count:"5",
              url:''
          },
          {
              name:"IOS高级工程师",
              salary:"6-8K",
              factorList:[
                  {
                      factorName:"金华"
                  },
                  {
                      factorName:"|"
                  },
                  {
                      factorName:"3年以上经验"
                  },
                  {
                      factorName:"|"
                  },
                  {
                      factorName:"大学学历"
                  },
                  {
                      factorName:"|"
                  },
                  {
                      factorName:"全职"
                  }
              ],
              pic:require('@/assets/img/gtlogo.png'),
              count:"5",
              url:''
          }
      ]
    };
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.contain {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .finish {
    width: 1000px;
    background: white;
    border-radius: 4px;
    padding: 80px 35px 45px;
    box-sizing: border-box;
    .success {
      text-align: center;
      font-size: 14px;
      color: #333;
      .icon {
        text-align: center;
        color: rgb(0, 189, 0);
        font-size: 36px;
      }
      .tips {
        font-size: 20px;
        text-align: center;
        margin-top: 30px;
        color: #333;
      }
      .preview {
        color: #276bf2;
        margin: 10px 0 25px;
      }
    }
    .menu {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .list {
        width: 120px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: white;
        font-size: 14px;
        background: #276bf2;
        border-radius: 4px;
        margin: 0 5px;
      }
    }
    .post{
        margin-top: 70px;
        .postTitle{
            font-size: 16px;
            color: #666;
        }
        .postList{
            margin-top: 25px;
            display: flex;
            justify-content: space-between;
            .postItem{
                width: 275px;
                background: #F9F9F9;
                font-size: 12px;
                color: #999;
                padding: 15px 10px;
                box-sizing: border-box;
                .postName{
                    font-size: 16px;
                    color: #276bf2;
                }
                .salary{
                    font-size: 16px;
                    font-weight: bold;
                    color: #FF6600;
                }
                .factor{
                    margin-right: 3px;
                    margin: 5px 5px 10px 0;
                }
                .line{
                    border-bottom: 1px solid #ECEBEB;
                    margin-bottom: 10px;
                }
                .logo{
                    width: 30px;
                    height: 30px;
                    margin-right: 5px;
                    img{
                        width: 100%;
                    }
                }
                b{
                    color: #276bf2;
                }
                .company{
                    color: #666;
                    font-size: 14px;
                }
            }
        }
    }
  }
}
</style>